import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import Webform from '../../plugins/gatsby-drupal-webform-unida'

const PageServices = ({data}) => {
    const nodeServices = data.nodeServices
    const form = data.form
    return (
        <div>
          <Layout translationPaths={homePagePaths}>
            <Header />
              <Title title={nodeServices?.title} color="bordeaux" type={1} className="size-56-line-64 d-flex justify-content-center space3" />
              <div dangerouslySetInnerHTML={{__html: nodeServices?.body?.processed}} className="simple-page" />
              <ContactForm data={form} />
            <Footer />
          </Layout>
        </div>
    )
}

const ContactForm = ({ data }) => {
    return(
        <Webform
          webform={data}
          endpoint={`${process.env.GATSBY_DRUPAL_API_URL}/react_webform_backend/submit`} // "http://localhost:8888/react_webform_backend/submit"
          onSuccess={(response) => navigate(response.settings.confirmation_url)}
        />
    )
    }

export const pageQuery = graphql`
query PageServices ($drupal_internal__nid: Int, $langcode: String!) {
    nodeServices (drupal_internal__nid: {eq: $drupal_internal__nid},langcode:{eq: $langcode}){
        relationships {
          field_ministry {
            title
          }
        }
        field_minimum
        field_duration_hours
        field_when
        title
        body {
          processed
        }
      }
      form: webformWebform(drupal_internal__id: {eq: "contact"}) {
        drupal_internal__id
        langcode
        elements {
          name
          type
          attributes {
            name
            value
          }
        }
      }
}
`

export default PageServices
